import { lazy } from 'react';

// ----------------------------------------------------------------------

const DigitalizationPage = lazy(() => import('src/pages/consulting/digitalization'));
const AIPage = lazy(() => import('src/pages/consulting/ai'));
const PMPage = lazy(() => import('src/pages/consulting/pm'));
const VoiceAgent = lazy(() => import('src/pages/consulting/voice-agent'));

// ----------------------------------------------------------------------

export const consultingRoutes = [
  {
    path: 'beratung',
    children: [
      { path: 'digitalisierung', element: <DigitalizationPage /> },
      { path: 'ki-strategie', element: <AIPage /> },
      { path: 'projekt-management', element: <PMPage /> },
      { path: 'virtuelle-agenten-fuer-Kleine-unternehmen', element: <VoiceAgent /> },

    ],
  },
];
