import { lazy } from 'react';

// ----------------------------------------------------------------------

const BrandingPage = lazy(() => import('src/pages/services/branding'));
const WebdesignPage = lazy(() => import('src/pages/services/webdesign'));
const WebsiterelaucnchPage = lazy(() => import('src/pages/services/relaunch'));
const GrafikdesignPage = lazy(() => import('src/pages/services/grafikdesign'));
const EcommercePage = lazy(() => import('src/pages/services/ecommerce'));
const VoiceAgentPage = lazy(() => import('src/pages/services/voice-agent'));



// ----------------------------------------------------------------------

export const servicesRoutes = [
  {
    path: 'leistungen',
    children: [
        { path: 'branding', element: <BrandingPage /> },
        { path: 'webdesign', element: <WebdesignPage /> },
        { path: 'webdesign/website-relaunch', element: <WebsiterelaucnchPage /> },
        { path: 'grafikdesign', element: <GrafikdesignPage /> },
        { path: 'ecommerce', element: <EcommercePage /> },
        { path: 'voice-agent', element: <VoiceAgentPage /> },
    ],
  },
];
