import { lazy, Suspense } from 'react';
import { Outlet, Navigate, useRoutes } from 'react-router-dom';

import MainLayout from 'src/layouts/main';
import { SplashScreen } from 'src/components/loading-screen';

import { authRoutes } from './auth';
import { errorRoutes } from './error';
import { z36Routes } from './z36';
import { agenturRoutes } from './agentur';
import { servicesRoutes } from './services';
import { consultingRoutes } from './consulting';


// ----------------------------------------------------------------------

const IndexPage = lazy(() => import('src/pages/z36/landing'));

export default function Router() {
  return useRoutes([
    {
      element: (
        <MainLayout>
          <Suspense fallback={<SplashScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      ),
      children: [
        { element: <IndexPage />, index: true },

        ...z36Routes,

        ...agenturRoutes,
        
        ...servicesRoutes,

        ...consultingRoutes,

      ],
    },

    ...authRoutes,

    ...errorRoutes,


    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
