// App.js
import 'src/global.css';
import ThemeProvider from 'src/theme';
import Router from 'src/routes/sections';
import { LocalizationProvider } from 'src/locales';
import ProgressBar from 'src/components/progress-bar';
import MotionLazy from 'src/components/animate/motion-lazy';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { useEffect, useRef } from 'react';

export default function App() {
  useScrollToTop();

  const cookieRef = useRef(null);

  // useEffect: Sayfa her yüklendiğinde, cookie değeri "true" ise GTM ve GA script'lerini yükle
  useEffect(() => {
    const consent = getCookieConsentValue();

    // Kullanıcı kabul ettiyse (consent === 'true'), GTM ve GA yükle
    if (consent === 'true') {
      // GTM çift yüklenmesin diye bayrak kontrolü
      if (!window.gtmLoaded) {
        window.gtmLoaded = true;
        addGoogleTagManager();
      }

      // GA çift yüklenmesin diye benzer bir bayrak
      if (!window.gaLoaded) {
        window.gaLoaded = true;
        addGoogleAnalytics();
      }
    }
  }, []);

  // Google Tag Manager'ı dinamik olarak ekleyen fonksiyon
  const addGoogleTagManager = () => {
    const GTM_ID = 'GTM-MB94ZR2'; // senin GTM ID’n
    if (document.getElementById('gtm-script')) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });

    const scriptEl = document.createElement('script');
    scriptEl.id = 'gtm-script';
    scriptEl.async = true;
    scriptEl.src = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`;
    document.head.appendChild(scriptEl);

    // noScript fallback
    const noScriptEl = document.createElement('noscript');
    noScriptEl.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${GTM_ID}"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(noScriptEl);
  };

  // Google Analytics (gtag.js) dinamik olarak ekleyen fonksiyon
  const addGoogleAnalytics = () => {
    const GA_ID = 'G-DYSJKF12RZ'; // senin GA ölçüm kimliğin
    if (document.getElementById('ga-script')) return;

    // ÖNCE gtag.js script'ini ekle
    const gaScriptEl = document.createElement('script');
    gaScriptEl.id = 'ga-script';
    gaScriptEl.async = true;
    gaScriptEl.src = `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`;
    document.head.appendChild(gaScriptEl);

    // Ardından GA ayarları için bir inline script ekle
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${GA_ID}');
    `;
    document.head.appendChild(inlineScript);
  };

  // Klick-Handler für "Alle akzeptieren"
  const handleAccept = () => {
    cookieRef.current?.accept(); 
    // GTM yükle
    if (!window.gtmLoaded) {
      window.gtmLoaded = true;
      addGoogleTagManager();
    }
    // GA yükle
    if (!window.gaLoaded) {
      window.gaLoaded = true;
      addGoogleAnalytics();
    }

    console.log('Cookie akzeptiert – GTM und GA geladen!');
  };

  // Klick-Handler für "Alle ablehnen"
  const handleDecline = () => {
    cookieRef.current?.decline();
    console.log('Cookies abgelehnt – GTM und GA werden nicht geladen.');
  };

  return (
    <LocalizationProvider>
      <ThemeProvider>
        <MotionLazy>
          <ProgressBar />
          <Router />
          <CookieConsent
            ref={cookieRef}
            cookieName="myCookieConsent"
            expires={365}
            location="bottom"
            disableStyles={true}
            buttonText=""
            declineButtonText=""
            enableDeclineButton={false}
            hideOnAccept={true}
            hideOnDecline={true}
            onAccept={() => {}}
            onDecline={() => {}}
            containerClasses="cky-consent-container"
            contentClasses="cky-consent-bar"
          >
            <p className="cky-title">Wir schätzen Ihre Privatsphäre</p>
            <div className="cky-notice-des">
              Wir verwenden Cookies, um Ihr Surferlebnis zu verbessern,
              personalisierte Werbung oder Inhalte bereitzustellen und
              unseren Datenverkehr zu analysieren. Mehr Infos in unserer{' '}
              <a
                href="/agentur/datenschutz"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'underline', color: '#1863dc' }}
              >
                Datenschutzerklärung
              </a>.
            </div>
            <div className="cky-notice-btn-wrapper" style={{ marginTop: '16px' }}>
              <button className="cky-btn cky-btn-reject" onClick={handleDecline}>
                Alle ablehnen
              </button>
              <button className="cky-btn cky-btn-accept" onClick={handleAccept}>
                Alle akzeptieren
              </button>
            </div>
          </CookieConsent>
        </MotionLazy>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
